import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import axios from 'axios';
import { GoogleMap, LoadScript, Circle } from '@react-google-maps/api';
import { ThemeProvider, theme, Title } from "@thetreep/components-library"

const containerStyle = {
  width: '100%',
  height: '80vh'
};

const Header = styled.div`
  height: 20vh;
  width: 100%;
  padding: 32px;
`

// Wrapper in order to center everything within page
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const center = {
  lat: 43.3,
  lng: 5.31
};

const circleOptions = {
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1
}

const App = () => {
  const [state] = useState({
    theme
  })

  const [ incidents, setIncidents ] = useState({error: {hasError: false, message: ""}, syncing: false, completed: false, data: []})
  const fetch = async () => {
    setIncidents({error: {hasError: false, message: ""}, syncing: true, completed: false, data: [...incidents.data]})
    try{
        const result = await axios.get(`https://trm-thetreep.herokuapp.com/incidents`)
        setIncidents({error: {hasError: false, message: ""}, syncing: false, completed: true, data:  [...result.data.result] })
    } catch(err) {
      setIncidents({error: {hasError: true, message: "Une erreur est survenue"}, syncing: false, completed: false, data: [...incidents.data]})
    }
  }

  useEffect(() => {
    let id = setInterval(() => {
      fetch()
    }, 4000);
    return () => clearInterval(id);
  });

  const colors = { terminated: "#80c904", inprogress: "#ff6600", pending: "#FF0000" }
  

  return (
    <Wrapper data-testid="app">
      <ThemeProvider initialStore={state.theme}>
        <>
          <Header>
            <Title size="h2">Argos</Title>
            <Title size="h4">Liste des incidents en cours</Title>
          </Header>
          <LoadScript
            googleMapsApiKey="AIzaSyAvxpRYKTMKjIthRP4T0DrDeNpQIStyd6c"
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={7}
            >
              { incidents.data.map(incident => {
                return(<Circle
                  key={incident._id}
                  center={{
                    lat: incident.attributes.location.latitude,
                    lng: incident.attributes.location.longitude
                  }}
                  // required
                  options={
                    {
                      ...circleOptions,
                      radius: incident.attributes.location.radius,
                      fillColor: colors[incident.status],
                      strokeColor: colors[incident.status],
                    }
                  }
                  onRightClick={() => console.log("click")}
                />)
              })}
              
            </GoogleMap>
          </LoadScript>
        </>
      </ThemeProvider>
    </Wrapper>
  )
}

export default React.memo(App)